import * as React from "react";
import FooterComponent from '../footer/footer';
import NavbarComponent from '../navbar/navbar';
import WhatWeDo2 from "../../Images/what-we-do-2.jpeg";
import ourModel from "../../Images/Our Model.svg";
import impact from "../../Images/Proving Our Impact Icon.svg";
import { Link } from 'react-router-dom';

const WhatWeDoComponent = (props: any) => {
    const myRef = React.useRef<HTMLDivElement>(null);
    // const [isMobile, setIsMobile] = React.useState(window.innerWidth < 1024 && window.innerWidth > 880);
    const [isTablet, setIsTablet] = React.useState(window.innerWidth > 576 && window.innerWidth < 992);
    const [isLessMobile, setIsLessMobile] = React.useState(window.innerWidth < 576);

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const istablet = window.innerWidth > 576 && window.innerWidth < 1024;
            const islessmobile = window.innerWidth <= 575;
            if (istablet !== isTablet) {
                setIsTablet(istablet);
            } else {
                setIsLessMobile(islessmobile)
            }
        }, false);

        if (myRef && myRef.current) {
            myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: "center"
            });
        }
    }, [isTablet, isLessMobile])
    return (
        <div className="main-body">
            <NavbarComponent />
            <section className='section'>
                <div className="container-fluid marginTop60">
                    <div className="hero--text textColorBlack marginBottom50"><h1 className="h10 hero--header">What We Do</h1><p className="hero--description balance-text">Roughly 1 in 8 hospital admissions in children’s hospitals come from rural families. Most of these families end up living with their child in their hospital room. You can help us help them.</p></div>
                    <div className="WhatWeDo">
                        <div className="side p40 marginTop20 flex justify-center flex">
                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 backgroundColorDefault padding20Left padding20Right textCenter fontFamilyTimesNewRoman":"p40 fontSize19 backgroundColorDefault textAlignLeft padding20Left padding20Right fontFamilyTimesNewRoman"}`}>At Aashray, we believe that no family should have to choose between their child's health and affordability. We strive to alleviate these concerns by funding, creating, or augmenting programs that provide families with accommodations during their child's hospitalization.</p>
                        </div>
                    </div>


                    <div className="WhatWeDo">
                        <div className="side p40 flex flex">
                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 padding20Left padding20Right backgroundColorDefault textCenter fontFamilyTimesNewRoman":"p40 fontSize19 backgroundColorDefault padding20Left padding20Right textAlignLeft fontFamilyTimesNewRoman"}`}>There is no better medicine than the love and support of our loved ones. By partnering with hospitals and local hotels, we offer families no-cost hotel rooms so that they can focus on their child's medical and emotional needs instead of worrying about unimportant tasks like cleaning or finding a place to do laundry. Depending on their accommodation, families can even de-stress and come together by taking advantage of various amenities such as en-suite kitchens, exercise rooms, and swimming pools.</p>
                        </div>
                    </div>

                    <div className="WhatWeDo">
                        <div className="side p40 flex justify-center flex">
                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 backgroundColorDefault textCenter fontFamilyTimesNewRoman padding20Left padding20Right":"p40 fontSize19 backgroundColorDefault textAlignLeft padding20Left padding20Right fontFamilyTimesNewRoman"}`}>We also believe that a family is stronger when they are united. By providing private, spacious, and comfortable accommodations, we aim to keep the families we serve supported so they can combat serious illness together.</p>
                        </div>
                    </div>

                    <div className="WhatWeDo">
                        <div className="side p40 flex justify-center flex">
                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 padding20Left padding20Right backgroundColorDefault textCenter fontFamilyTimesNewRoman":"p40 fontSize19 backgroundColorDefault textAlignLeft padding20Left padding20Right fontFamilyTimesNewRoman"}`}>Currently, we are helping families at The Children’s Hospital at Saint Peter’s University Hospital and Bristol Myers Squibb Children’s Hospital in New Brunswick, New Jersey. We host our families through our partnership with Home2 Suites in New Brunswick. We want to express our profound thanks to Home2 Suites for generously offering their services. </p>
                        </div>
                    </div>

                    <div className="WhatWeDo">
                        <div className="side p40">
                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 padding20Left padding20Right backgroundColorDefault textCenter fontFamilyTimesNewRoman":"p40 fontSize19 backgroundColorDefault textAlignLeft padding20Left padding20Right fontFamilyTimesNewRoman"}`}>We hope that our services complement the care families are already receiving and that they find a bit of home when they need it most. You can help us support families by donating  <Link to="/donation">here</Link></p>
                        </div>
                    </div>

                    <div className="founderDivImage marginTop50 marginBottom50">
                        <img className="responsive ImageOfFounder" alt="Founder Story" src={WhatWeDo2} />
                    </div>

                    {
                        isTablet || isLessMobile ? (
                            <div className="row howWeWork margin30bottom">
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={ourModel} alt="Our Model" className="responsive imgHowWeWork" />
                                        </div>
                                        <div className="col-sm-9">
                                            <h2 className={`${isLessMobile ? "h50 margin10bottom textCenter" : "h50 margin10bottom textCenter"}`}>Our Model</h2>
                                            <p className={`${isTablet || isLessMobile ? "p40 padding20Left padding20Right fontSize19 backgroundColorDefault textCenter fontFamilyTimesNewRoman":" padding20Left padding20Right p40 fontSize19 backgroundColorDefault textAlignLeft fontFamilyTimesNewRoman"}`}>
                                                By using the local hotels around hospitals, Aashray can offer exceptional privacy, comfort, and even amenities without the hurdle of high formation costs, such as the construction or remodeling of expensive buildings. This allows us to quickly enter areas of need and begin helping families.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={impact} alt="Proving Our Impact" className="responsive imgHowWeWork" />
                                        </div>
                                        <div className="col-sm-9">
                                            <h2 className={`${isLessMobile ? "h50 margin10bottom textCenter" : "h50 margin10bottom textCenter"}`}>Proving Our Impact</h2>
                                            <p className={`${isTablet || isLessMobile ? "p40 fontSize19 backgroundColorDefault textCenter fontFamilyTimesNewRoman padding20Left padding20Right":"p40 fontSize19 backgroundColorDefault textAlignLeft fontFamilyTimesNewRoman padding20Left padding20Right"}`}>
                                            From the beginning, we wanted Aashray to be data dependent. Thus, we use several quantitative and qualitative metrics to measure the success and failures of our operations, and then better our programs while providing transparency. If you're one of our patient families you can rate your experience <a href="https://form.jotform.com/233025355754455">here</a>, or if you're interested in knowing more about our effectiveness, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="row howWeWork margin30bottom">
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={ourModel} alt="Our Model" className="responsive boardOfDirectorImage" />
                                        </div>
                                        <div className="col-sm-9">
                                            <h2 className="h50 margin10bottom textCenter">Our Model</h2>
                                            <p className="p40 fontSize19 backgroundColorDefault textAlignLeft fontFamilyTimesNewRoman padding20Left padding20Right">
                                                By using the local hotels around hospitals, Aashray can offer exceptional privacy, comfort, and even amenities without the hurdle of high formation costs, such as the construction or remodeling of expensive buildings. This allows us to quickly enter areas of need and begin helping families.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <img src={impact} alt="Proving Our Impact" className="responsive boardOfDirectorImage" />
                                        </div>
                                        <div className="col-sm-9">
                                            <h2 className="h50 margin10bottom">Proving Our Impact</h2>
                                            <p className="p40 fontSize19 backgroundColorDefault textAlignLeft fontFamilyTimesNewRoman">
                                                From the beginning, we wanted Aashray to be data dependent. Thus, we use several quantitative and qualitative metrics to measure the success and failures of our operations, and then better our programs while providing transparency. If you're one of our patient families you can rate your experience <a href="https://form.jotform.com/233025355754455">here</a>, or if you're interested in knowing more about our effectiveness, drop us an email at <a href="mailto:aashraycharities@gmail.com">aashraycharities@gmail.com</a>.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
            </section>
            <FooterComponent />
        </div>
    )
}
export default WhatWeDoComponent;