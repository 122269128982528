import * as React from "react";
import FooterComponent from "../footer/footer";
import WhoWeAreComponent from "./who_we_are";
import Founders_True_Story from "../../Images/Founders_True_Story.jpg";

const FounderStoryComponent = (props: any) => {
    return (
        <div className="main-body">
            <WhoWeAreComponent />
            <section className='section'>
                <div className="textAlignCenter marginTop30">
                    <span className='founderStorySpan fontWeightBold'>Our Founder's Story</span>
                </div>

                <div className="marginTop10">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        Constrained to a small cot by the lone window in the room, my mother tossed and turned, fluffing her deflated pillow, and attempting to stretch out her legs. Despite her 5’2” frame, the cot felt too short and too narrow. But it hardly mattered. Since my diagnosis of IgA Nephropathy, my mother had become accustomed to restless nights, practically learning to sleep with one eye open. Today, however, was a momentous evening. I had just received a kidney transplant. The constant beeping of machines, a familiar soundtrack from my childhood in the ICU, was now interspersed with my groaning, creating a terrible lullaby for her to endure. Nonetheless, her primary concern remained my well-being. Frequently waking to console my whimpering, she patiently awaited her only respite, my father's arrival in the morning.
                    </p>
                </div>

                <div className="founderDivImage">
                    <img className="responsive ImageOfFounder" alt="Founder Story" src={Founders_True_Story} />
                </div>

                <div className="marginTop25">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        My father arrived after the traffic-ridden hour-long drive to Philadelphia. After sending my sister off to school, the usual congestion from Academy Rd. to Girard Ave. was unusually exhausting today. But encouraged by the promise of a successful operation and driven to grant my mother a reprieve, he summoned all his energy. Greeting my mother, they exchanged weary smiles. But before they had time to relax, the transplant team made their rounds. Fortunately, they brought great news. Inspired, my parents began eating the breakfast my father had brought. However, as it so often did, their focus quickly shifted back to my well-being. Unfortunately, my father could only offer my mother a few hours of rest before he had to leave to attend to work and beat the afternoon rush. Of course, he promised to bring back dinner.
                    </p>
                </div>

                <div className="margin10Top">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        My name is Samarth Mehta. I was diagnosed with an autoimmune kidney disease at the age of eight at The Children’s Hospital at Saint Peter’s University Hospital, underwent rounds of chemotherapy, and was treated for renal failure at Bristol Myers Squibb Children’s Hospital, before receiving a life-saving kidney transplant at the age of twelve at the Children’s Hospital of Philadelphia. Amidst all this chaos, the one constant was the unselfish care my parents provided. They have given me more than I can ever hope to repay.
                    </p>
                </div>

                <div className="margin10Top">
                    <p className="padding10  p30 fontSize17 max-w-sm mx-auto backgroundColorDefault">
                        But my parents are not alone. Every parent of a child with medical needs sacrifices more than their children can imagine. Still, I was one of the lucky ones. My parents could endure the emotional, financial, and logistical challenges for my medical needs. However, thousands of families are less fortunate. That is why I founded Aashray Charities, a nonprofit organization dedicated to helping families with hospitalized children find a private place to live so they can rest, reboot, and recharge. At Aashray, we believe that by improving the well-being of families, we can improve the well-being of their children as well.
                    </p>
                </div>
                <div className="space"></div>
            </section>
            <FooterComponent />
        </div >
    )
}
export default FounderStoryComponent;